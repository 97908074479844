body {
  margin: 0;
  font-family: 'Inter', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
input, textarea, button {
  font-family: 'Inter', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
a {
  color: #7c52ff;
}

span.MuiSlider-thumb{
  background: none;
  pointer-events: none;
  overflow: hidden;
  transition: none;
}
span.MuiSlider-root {
  pointer-events: none;
}
span.MuiSlider-rail {
  height: 7px;
  color: #e8e8e8;
}
span.MuiSlider-track {
  height: 5px;
}

span.MuiSwitch-root {
  bottom: 5px;
}
span.MuiSwitch-thumb {
  position: relative;
  top: 5px;
  color: #01d1ff;
  height: 20px;
  width: 15px;
  margin-left: 5px;
  margin-right: 5px;
  border-radius: 10px;
}
span.MuiSwitch-track {
  width: 50px;
  opacity: 100;
  border-radius: 8px;
  padding: 6px 20px 6px;
  background: white;
  color: white;
}


/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

input[type="checkbox"] {
  display: grid;
  place-content: center;
}

input[type="checkbox"]::before {
  content: "";
  width: 0.65em;
  height: 0.65em;
  transform: scale(0);
  transition: 120ms transform ease-in-out;
  box-shadow: inset 1em 1em var(--form-control-color);
}

input[type="checkbox"]:checked::before {
  transform: scale(1);
}
