
tbody>th>:nth-child(1) {
  color: white;
}

tbody>tr>:nth-child(1) {
  color: #01c1ee;
}

.left-column-cell {
  color: #01c1ee;
}
